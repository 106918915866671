import React, {useState, useRef, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../common/index'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
import ButtonCal from '../../../../localMedia/buttonCal.svg'
import {KTCardBody, KTSVG} from '../../../../_metronic/helpers'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import OrgNonComplianceReportsPopup from './OrgNonComplianceReportsPopup'

export default function OrgNonComplianceReports(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [Reportsdata, setReports_Data] = useState<any | []>([])
  const [consultant_type, setConsultantType] = useState('')
  const [orgList, setOrgList] = useState<any | []>([])
  const [selectProjectType, setSelectProjectType] = useState<any>([])
  const [test, setTest] = useState<any>(new Date())
  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [order12, setOrder12] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const [perPage, setPerPage] = useState<any>(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [validatedescription, setValidateDescription] = useState<any>(false)
  const [MonthWiseViewPopOpen, setMonthWiseViewPopOpen] = useState<any>(false)
  const [AllMonthOptions, setAllMonthOptions] = useState<any | []>([
    // {
    //   label: 'Select All',
    //   value: 'All',
    // },
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ])
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ])
  const [AllProjectTypeOptions, setAllProjectTypeOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'Placement',
      value: '1',
    },
    {
      label: 'Bench',
      value: '2',
    },
    {
      label: 'Internal',
      value: '3',
    },
  ])
  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })

  type UserSubmitForm = {
    employee_type: string
    org_name: any
    select_Year: any
    project_type: any
    work_city: string
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [ReportData, setReportData] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState([])
  const [selectMonth, setSelectMonth] = useState<any>([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const [ProjectReportProjectsData, setProjectReportProjectsData] = useState<any | []>([])

  const [country1, setCountry1] = useState<any | []>([])
  const [candidate, setCandidate] = useState<any | []>([])
  const [candidateList, setCandidateList] = useState<any | []>([])

  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef2: any = useRef<HTMLInputElement>(null)
  const typeaheadRef3: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)

  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [years, setYears] = useState<any>([])
  const [selectYear, setSelectYear] = useState('')
  const [ProjectReportData, setProjectReportData] = useState<any | []>([])
  const [MonthwiseProjectReportData, setMonthwiseProjectReportData] = useState<any | []>([])
  const [ProjectReportCountData, setProjectReportCountData] = useState<any | []>([])

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    setShowBackDrop(false)
  }

  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ]
  const options3: any = [
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ]

  const [selectProjectStatus, setSelectProjectStatus] = useState<any>([])
  const [AllProjectStatusOptions, setAllProjectStatusOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'In Progress',
      value: 'IN_PROGRESS',
    },
    {
      label: 'Initiated',
      value: 'INITIATED',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      label: 'Rejected',
      value: 'REJECTED',
    },
  ])

  useEffect(() => {
    // handleConsultant()
    let currYr: any = new Date().getFullYear()
    setSelectYear(currYr)
    let pastYr = 2013
    while (currYr >= pastYr) {
      // setYears([...currYr]);
      years.push(currYr)
      currYr -= 1
    }
    setTest(new Date(moment().subtract(1, 'month').format('YYYY')))
    AllOrgList()
    const MonthNumberDefault = [
      {
        label: moment(new Date()).subtract(1, 'month').format('MMMM'),
        value: moment(new Date()).subtract(1, 'month').format('M'),
      },
    ]
    setSelectMonth(MonthNumberDefault)
  }, [])

  const AllOrgList = async () => {
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let currentDate: any = new Date()
    // if (test) {
    //   setTest(test)
    // } else {
    //   setTest(currentDate)
    // }
    let article = {
      org_id: Number(localStorage.getItem('org_id')),
      // year: test
      //   ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
      //   : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      year: test ? moment(test).subtract(1, 'month').format('YYYY') : '',
      month: [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: '',
      user_id: null,
      project_id: null,
      is_placement_project: null,
      business_unit_id: null,
      pagenumber: page,
      pagesize: Number(perPage),
    }
    setShowBackDrop(true)
    setFlag(true)
    const response: any = await Apiservices.get_project_wise_yearly_non_compliance_report_total(
      article
    )
    if (response && response.isError == false) {
      setProjectReportData(response.data)
      setPages(Math.ceil(response?.count[0]?.count / Number(perPage)))
    }
    setShowBackDrop(false)
  }

  useEffect(() => {
    get_business_units_list()
    getAllProjects()
  }, [])
  const getAllProjects = async () => {
    const res: any = await Apiservices.getprojectbyname({})
    // if (res.data.isError == false) {
    //   setProjectReportCountData(res.data.data.length)
    // }
  }

const ErrorToast = (message: any) => {
      toast.error(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    }

  const get_business_units_list = async () => {
    // const res: any = await Apiservices.get_business_units_list()
    // if (res && res.status == 200) {
    //   if (res.data.data.length > 0) {
    //     // setBusinessUnitsList(res.data.data)
    //     let obj = {name: 'Select All', value: 'All'}
    //     setBusinessUnitsList([obj, ...res.data.data])
    //   }
    // }

    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter(
      (element: any) =>
        element.roleName?.toLowerCase() == 'accounts' ||
        element.roleName?.toLowerCase() == 'approver'
      // element.roleName?.toLowerCase() == 'timesheetadmin'
    )
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Select All', value: 'All'}
        if (numArray?.includes('admin') || numArray?.includes('timesheetadmin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
        }
      }
    }
  }

  const getallprojects: any = async (userlist: any, limit: number, offset: number) => {
    setShowBackDrop(true)
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')
    let currentDate: any = new Date()
    if (test) {
      setTest(test)
    } else {
      setTest(currentDate)
    }

    let article = {
      org_id: Number(localStorage.getItem('org_id')),
      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      month: month12 ? month1 : null, // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : '',
      user_id: employee1 ? employee : null,
      project_id: project1 ? project : null,
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : null,
      pagenumber: limit,
      pagesize: Number(offset),
    }

    const data: any = await Apiservices.get_project_wise_yearly_non_compliance_report_total(article)
    if (data && data.isError == false) {
      setProjectReportData(data.data)
      setPages(Math.ceil(data.count[0].count / Number(offset)))
    }
    setShowBackDrop(false)
  }

  const handleClosePopup = () => {
    setMonthWiseViewPopOpen(false)
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      handleClick(1, value)
    }
  }

  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)

    handleClick(selected, perPage)
  }

  const handleClick = async (page: any, perPage: any) => {
    // const handleClick = async () => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
      setProjectReportData([])
      setFlag(false)
    } else {
      setValidateDescription(false)
    }

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    let project_status1 = selectProjectStatus.map((temp: any) => {
      return temp.value
    })
    let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')

    let currentDate: any = new Date()
    if (test) {
      setTest(test)
    } else {
      setTest(currentDate)
    }
    // const MonthNumberDefault = [
    //   {
    //     label: moment(new Date()).subtract(1, 'month').format('MMMM'),
    //     value: moment(new Date()).subtract(1, 'month').format('M'),
    //   },
    // ]
    // if (month12) {
    //   setSelectMonth(selectMonth)
    // } else {
    //   setSelectMonth(MonthNumberDefault)
    // }

    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      console.log('if')
    } else {
      setShowBackDrop(true)
      setFlag(true)
      let article1 = {
        org_id: Number(localStorage.getItem('org_id')),
        year: test
          ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
          : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        user_id: employee1 ? employee : null,
        project_id: project1 ? project : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : null,
        project_status: project_status2 ? project_status2 : null,

        keyword: search ? search : null,
        pagenumber: null,
        pagesize: null,
      }
      if (perPage == null || perPage == '') {
        const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
          article1
        )
        if (res1.isError == false) {
          setProjectReportCountData(res1.count[0].count)
          var count_All_id = res1.count[0].count
          setProjectReportProjectsData(res1.data)
          var project_All_id = res1.data
        }
      }
      let article = {
        org_id: Number(localStorage.getItem('org_id')),
        year: test
          ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
          : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
        // month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        user_id: employee1 ? employee : null,
        project_id: project1 ? project : project_All_id ? project_All_id : null,
        // is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : null,
        project_status: project_status2 ? project_status2 : null,

        pagenumber: page,
        // pagesize: count > 0 ? Number(count) : Number(perPage),
        // if (perPage == null || perPage == '') {
        pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
      }
      const response: any = await Apiservices.get_project_wise_yearly_non_compliance_report_total(
        article
      )
      if (response && response.isError == false) {
        setProjectReportData(response.data)
        // setPages(Math.ceil(response?.count[0]?.count / Number(perPage)))
        if (perPage == null || perPage == '') {
          setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
        } else {
          setPages(Math.ceil(response.count[0].count / Number(perPage)))
        }
        // setProjectReportCountData(response?.count[0]?.count)
      }
      setShowBackDrop(false)
    }
  }

  const ref: any = useRef<HTMLInputElement>(null)

  const resetData = async (e: any) => {
    setFlag(false)
    setSearch('')
    setConsultantType('')
    setValue('employee_type', '')
    setValue('work_city', '')
    setTest(null)
    typeaheadRef1.current.clear()
    setSelected([])
    setSelectMonth([])
    setSelectProjectType([])
    typeaheadRef.current.clear()
    setSelectConsultant([])
    typeaheadRef2.current.clear()
    setSelectconsultant([])
    typeaheadRef3.current.clear()
    setCountries1([])
    setBusinessUnit([])
    typeaheadRef4.current.clear()
    setSelectProjectStatus([])

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectTypeReset) {
      AllProjectTypeOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
      // setAllProjectTypeOptions(AllProjectTypeOptions)
    }
    // AllProjectTypeOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let projectTypeReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectTypeReset1) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let monthReset = AllMonthOptions.find((item: any) => item.value == 'All')
    // if (!monthReset) {
    //   AllMonthOptions.unshift({
    //     label: 'Select All',
    //     value: 'All',
    //   })
    // }
    // AllMonthOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let monthReset1 = AllMonthOptions.find((item: any) => item.value == 'empty')
    if (monthReset1) {
      AllMonthOptions.splice(
        AllMonthOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }
  const handleChange1 = async (value: any) => {
    setSearch(value)
    // setSearch_Open(true)
    setSelectConsultant([])
    setBusinessUnit([])
    setSelectProjectType([])
    setSelectconsultant([])
    setCountries1([])

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectTypeReset) {
      AllProjectTypeOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }

    let projectTypeReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectTypeReset1) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }

    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let monthReset = AllMonthOptions.find((item: any) => item.value == 'All')

    let monthReset1 = AllMonthOptions.find((item: any) => item.value == 'empty')
    if (monthReset1) {
      AllMonthOptions.splice(
        AllMonthOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')
    let currentDate: any = new Date()

    let article1 = {
      org_id: Number(localStorage.getItem('org_id')),
      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : '',
      user_id: employee1 ? employee : null,
      project_id: project1 ? project : null,
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : null,
      keyword: value,
      pagenumber: null,
      pagesize: null,
    }
    if (perPage == null || perPage == '') {
      const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
        article1
      )
      if (res1.isError == false) {
        setProjectReportCountData(res1.count[0].count)
        var count_All_id = res1.count[0].count
        setProjectReportProjectsData(res1.data)
        var project_All_id = res1.data
      }
    }
    let article = {
      org_id: Number(localStorage.getItem('org_id')),
      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      // month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : '',
      user_id: employee1 ? employee : null,
      project_id: project1 ? project : project_All_id ? project_All_id : null,
      // is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : null,
      keyword: value,
      pagenumber: page,
      // pagesize: count > 0 ? Number(count) : Number(perPage),
      // if (perPage == null || perPage == '') {
      pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
    }
    const response: any = await Apiservices.get_project_wise_yearly_non_compliance_report_total(
      article
    )
    if (response && response.isError == false) {
      setProjectReportData(response.data)
      // setPages(Math.ceil(response?.count[0]?.count / Number(perPage)))
      if (perPage == null || perPage == '') {
        setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
      } else {
        setPages(Math.ceil(response.count[0].count / Number(perPage)))
      }
      // setProjectReportCountData(response?.count[0]?.count)
    }
  }
  // const handleProjectType = (e: any) => {
  //   setValue('project_type', e.target.value)
  // }
  const exporthandleClick = async (e: any) => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
      setProjectReportData([])
      setFlag(false)
    } else {
      setValidateDescription(false)
    }

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    let project_status1 = selectProjectStatus.map((temp: any) => {
      return temp.value
    })
    let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')

    let currentDate: any = new Date()
    if (test) {
      setTest(test)
    } else {
      setTest(currentDate)
    }
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      console.log('if')
    } else {
      setShowBackDrop(true)

      let article1 = {
        org_id: Number(localStorage.getItem('org_id')),
        year: test
          ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
          : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        user_id: employee1 ? employee : null,
        project_id: project1 ? project : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : null,
        project_status: project_status2 ? project_status2 : null,
      }
      // if (perPage == null || perPage == '') {
      const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
        article1
      )
      if (res1.isError == false) {
        setProjectReportCountData(res1.count[0].count)
        var count_All_id = res1.count[0].count
        setProjectReportProjectsData(res1.data)
        var project_All_id = res1.data
      }
      // }

      let article = {
        org_id: Number(localStorage.getItem('org_id')),
        year: test
          ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
          : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
        // month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        month: month12 ? month1 : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        user_id: employee1 ? employee : null,
        // project_id: project1 ? project : project_All_id,
        project_id: project1 ? project : project_All_id ? project_All_id : null,

        // is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : null,
        project_status: project_status2 ? project_status2 : null,
      }
      const response: any = await Apiservices.ExportProjectWiseYearlyNonComplianceReport(article)

      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = response?.data?.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
      setShowBackDrop(false)
    }
  }
  let FilteredData = ReportData?.data?.filter(
    (each: any) =>
      each?.employee_id?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.employee_type?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.joining_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.leaving_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.emp_working_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.total_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.compliance_percentage?.toLowerCase()?.includes(search?.toLowerCase())
  )
  const singleDateSelection = async (date: any) => {
    setTest(date)
  }
  const handleCountries1 = async (country1: any) => {
    // if (country1 && country1.length >= 2) {
    const response: any = await Apiservices.getprojectbyname({country_name: country1})
    if (response?.data?.data !== null) {
      setCountry1(response && response.data && response.data.data ? response?.data?.data : '')
    }
    // }
  }

  const handleView = async (userInfo: any) => {
    setShowBackDrop(true)
    let article = {
      org_id: Number(localStorage.getItem('org_id')),
      year: test ? moment(test, 'ddd MMM DD YYYY').format('YYYY'):'',     
      month: [Number(userInfo?.month)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: '',
      user_id: [userInfo?.user_id],
      project_id: null,
      is_placement_project: null,
      business_unit_id: null,
      // pagenumber: page,
      // pagesize: Number(perPage),
    }
    setShowBackDrop(true)
    const response: any = await Apiservices.get_project_wise_yearly_non_compliance_report(article)
    if (response && response.isError == false) {
      setMonthwiseProjectReportData(response.data)
      setPages(Math.ceil(response?.count[0]?.count / Number(perPage)))
      setMonthWiseViewPopOpen(true)
    }else{
      ErrorToast('Error Occured')
    }
    setShowBackDrop(false)
  }

  const selectedCountries1 = async (state: any) => {
    setCountries1(state)
  }
  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const handleConsultant = async (consult_Name?: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidate(response?.data?.data)
  }
  const handleFilterConsultant = async (consult_Name: any) => {
    // let filteredConsultant = candidate?.filter((each: any) =>
    //   each?.full_name?.toLowerCase()?.includes(consult_Name?.toLowerCase())
    // )
    // setCandidateList(filteredConsultant)
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList(response?.data?.data)
  }

  const selectedConsultant = async (consult_name: any) => {
    setSelectconsultant(consult_name)
  }

  const handleMonth = (selected: any) => {
    // setSelectMonth(selected)
    if (selected?.length > 0) {
      setValidateDescription(false)
    }
    if (selected.length > 0) {
      if (selected.length < 4 || selected.find((item: any) => item.value == 'empty')) {
        for (let each of selected) {
          // if (each.value == 'All') {
          //   let x = AllMonthOptions.filter(
          //     (temp: any) => temp.value != 'All' && temp.value != 'empty'
          //   )
          //   setSelectMonth(x)
          //   AllMonthOptions.shift({
          //     label: 'Select All',
          //     value: 'All',
          //   })
          //   let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
          //   if (!abc) {
          //     AllMonthOptions.push({
          //       label: 'Deselect All',
          //       value: 'empty',
          //     })
          //     // setAllMonthOptions(AllMonthOptions)
          //   }
          // }
          if (each.value == 'empty') {
            setSelectMonth([])
            let cde = AllMonthOptions.find((item: any) => item.value == 'All')
            // if (!cde) {
            //   AllMonthOptions.unshift({
            //     label: 'Select All',
            //     value: 'All',
            //   })
            //   // setAllMonthOptions(AllMonthOptions)
            // }
            AllMonthOptions.pop({
              label: 'Deselect All',
              value: 'empty',
            })
          } else {
            setSelectMonth(selected)
            let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
            let cde = AllMonthOptions.find((item: any) => item.value == 'All')

            if (!abc) {
              AllMonthOptions.push({
                label: 'Deselect All',
                value: 'empty',
              })
              // setAllMonthOptions(AllMonthOptions)
            }

            // if (!cde) {
            //   AllMonthOptions.unshift({
            //     label: 'Select All',
            //     value: 'All',
            //   })
            //   setAllMonthOptions(AllMonthOptions)
            // }
            let check_drop = selected.find((item: any) => item.value == 'All')
            let check_drop1 = selected.find((item: any) => item.value == 'empty')

            if (!check_drop && !check_drop1) {
              let test = AllMonthOptions.filter(
                (id: any) => !selected.some((x: any) => x.value == id.value)
              )
              if (test.length == 2) {
                let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
                // AllMonthOptions.shift({
                //   label: 'Select All',
                //   value: 'All',
                // })
              }
            }
          }
        }
      }
    } else {
      setSelectMonth(selected)
      // AllMonthOptions.pop({
      //   label: 'Deselect All',
      //   value: 'empty',
      // })

      // let monthReset = AllMonthOptions.find((item: any) => item.value == 'All')
      let monthReset1 = AllMonthOptions.find((item: any) => item.value == 'empty')

      if (monthReset1) {
        AllMonthOptions.pop({
          label: 'Deselect All',
          value: 'empty',
        })
      }
      // if (!monthReset) {
      //   AllMonthOptions.unshift({
      //     label: 'Select All',
      //     value: 'All',
      //   })
      // }
    }
  }

  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }
  const handleProjectType = (selected: any) => {
    setSelectProjectType(selected)
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectTypeOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectType(x)
          AllProjectTypeOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectType([])
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          AllProjectTypeOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectType(selected)
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectTypeOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectTypeOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectType(selected)
      AllProjectTypeOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
      if (!projectTypeReset) {
        AllProjectTypeOptions.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleProjectStatus = (selected: any) => {
    // setSelectProjectStatus(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectStatusOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectStatus(x)
          AllProjectStatusOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectStatusOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectStatus([])
          let cde = AllProjectStatusOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectStatusOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectStatusOptions(AllProjectStatusOptions)
          }
          AllProjectStatusOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectStatus(selected)
          let abc = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectStatusOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectStatusOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }

          if (!cde) {
            AllProjectStatusOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectStatusOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectStatusOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectStatus(selected)
      AllProjectStatusOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let projectStatusReset = AllProjectStatusOptions.find((item: any) => item.value == 'All')
      if (!projectStatusReset) {
        AllProjectTypeOptions.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }

  const sorting5 = (col: any) => {
    if (order5 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))
      setProjectReportData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))
      setProjectReportData(sorted)

      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // (a[col] > b[col] ? 1 : -1)
        // if (numA === numB) {
        //   // If numeric parts are equal, compare the whole strings
        //   return a[col].localeCompare(b[col])
        // } else {
        //   return numA - numB
        // }
        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerA.localeCompare(lowerB)
      })
      // const sorted = [...mainTableData].sort((a, b) => {
      //   return a[col].localeCompare(b[col], undefined, {
      //     numeric: true,
      //     sensitivity: 'base',
      //   })
      // })
      setProjectReportData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // (a[col] < b[col] ? 1 : -1)
        // if (numA === numB) {
        //   // If numeric parts are equal, compare the whole strings
        //   return b[col].localeCompare(a[col])
        // } else {
        //   return numB - numA
        // }
        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerB.localeCompare(lowerA)
      })

      // const sorted = [...mainTableData].sort((a, b) => {
      //   return b[col].localeCompare(a[col], undefined, {
      //     numeric: true,
      //     sensitivity: 'base',
      //   })
      // })
      setProjectReportData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  const sorting7 = (col1: any, col2: any) => {
    if (order7 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() > val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() < val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }

  const sorting8 = (col: any) => {
    if (order8 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => a[col] - b[col])

      setProjectReportData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => b[col] - a[col])
      setProjectReportData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }

  const sorting12 = (col: any, col1: any, col2: any) => {
    if (order12 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => {
        let a1 = a[col] == 'CANCELLED' ? a[col1] : a[col2]
        let b1 = b[col] == 'CANCELLED' ? b[col1] : b[col2]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportData(sorted)
      setOrder12('DSC')
      setSort(false)
    }

    if (order12 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => {
        let a1 = a[col] == 'CANCELLED' ? a[col1] : a[col2]
        let b1 = b[col] == 'CANCELLED' ? b[col1] : b[col2]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportData(sorted)
      setOrder12('ASC')
      setSort(true)
    }
  }
  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Organization Non Compliance Report </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BackDrop showBackDrop={showBackDrop} />
            {loading ? (
              <>
                {' '}
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    <div className='row gy-3 align-items-center py-2'>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label fw-semibold'>Select Year *</label>
                        <DatePicker
                          selected={test}
                          onChange={(date: any) => singleDateSelection(date)}
                          placeholderText='Select Year'
                          className={clsx('form-control')}
                          dateFormat='yyyy'
                          showYearPicker
                          // required
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg '>
                        <label className='form-label '>Select Month *</label> (Maximum 3 months)
                        {/* <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            setSelectMonth(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef1.current.toggleMenu()
                          }}
                          options={options3}
                          placeholder='Select Month'
                          ref={typeaheadRef1}
                          selected={selectMonth}
                        /> */}
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectMonth(selected)
                            handleMonth(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef1.current.toggleMenu()
                          }}
                          options={AllMonthOptions}
                          placeholder='Select Month'
                          ref={typeaheadRef1}
                          selected={selectMonth}
                          // inputProps={{
                          //   required: true,
                          // }}
                        />
                        {validatedescription && (
                          <span className='error text-danger mb-0'>Month is required</span>
                        )}
                        <div className='invalid-feedback'>{errors.employee_type?.message}</div>
                      </div>

                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Consultant Type</label>
                        {/* <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            setSelectConsultant(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef.current.toggleMenu()
                          }}
                          options={options2}
                          placeholder='Select Consultant Type'
                          ref={typeaheadRef}
                          selected={selectConsultant}
                        /> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            handleConsultantType(selected)
                            // setSelectConsultant(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef.current.toggleMenu()
                          }}
                          options={AllOptions}
                          placeholder='Select Consultant Type'
                          ref={typeaheadRef}
                          selected={selectConsultant}
                        />
                        {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Business Unit</label>
                        {/* <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            setBusinessUnit(e)
                            typeaheadRef4.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef4}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        >
                          
                        </Typeahead> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleBusinessUnit(e)
                            typeaheadRef4.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef4}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                        {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Project Type </label>
                        {/* <select
                          // value={selectedItem}
                          {...register('project_type')}
                          onChange={handleProjectType}
                          className={clsx('form-select')}
                        >
                          <option value=''>Select Project Type</option>
                          <option value='1'>Placement</option>
                          <option value='2'>Bench Project</option>
                          <option value='3'>Internal</option>
                        </select> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectProjectType(selected)
                            handleProjectType(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef5.current.toggleMenu()
                          }}
                          options={AllProjectTypeOptions}
                          placeholder='Select Project Type'
                          ref={typeaheadRef5}
                          selected={selectProjectType}
                        />

                        {/* <div className='invalid-feedback'>{errors.user_status?.message}</div> */}
                      </div>

                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Consultant Name</label>
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            selectedConsultant(e)
                            typeaheadRef2.current.toggleMenu()
                          }}
                          onInputChange={(e) => handleFilterConsultant(e)}
                          options={candidateList}
                          ref={typeaheadRef2}
                          placeholder='Search Consultant'
                          selected={select_consultant}
                          labelKey={(candidateList: any) => user(candidateList.full_name)}
                          //   inputProps={{required: true}}
                        >
                          {/* {({onClear, selected}) => (
                    <div className='rbt-aux'>
                      {!!selected.length && (
                        <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                      )}
                    </div>
                  )} */}
                        </Typeahead>
                        {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Project Name</label>
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(e) => {
                            selectedCountries1(e)
                            typeaheadRef3.current.toggleMenu()
                          }}
                          onInputChange={(e) => {
                            handleCountries1(e)
                          }}
                          placeholder='Search Project Name'
                          ref={typeaheadRef3}
                          options={country1}
                          selected={countries1}
                          labelKey={(country1: any) => country1.project_name}
                        >
                          {/* {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )} */}
                        </Typeahead>
                        {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Project Status </label>
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectProjectStatus(selected)
                            handleProjectStatus(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef6.current.toggleMenu()
                          }}
                          options={AllProjectStatusOptions}
                          placeholder='Select Project Status'
                          ref={typeaheadRef6}
                          selected={selectProjectStatus}
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Search</label>
                        <input
                          type='text'
                          id='form1'
                          className='form-control'
                          // value={search}
                          {...register('work_city')}
                          placeholder='Search'
                          onChange={(e) => handleChange1(e.target.value)}
                        />
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          className='btn btn-sm btn-primary'
                          type='submit'
                          // onClick={handleClick}
                          disabled={selectMonth.length > 3 ? true : false}
                          onClick={() => handleClick(page, perPage)}
                        >
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                          disabled={selectMonth.length > 3 ? true : false}
                        >
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select year, month and click run to view the report
            </div>
          )}
          {flag == true && (
            <>
              <KTCardBody className='py-1 '>
                <div className='pt-3'>
                  <div className='card card-custom card-flush '>
                    <div className='card-body p-3 py-3'>
                      <div className='table-responsive padding-down reportTab'>
                        <table
                          id='kt_table_users'
                          className='table table-row-bordered table-row-gray-300 gy-4'
                        >
                          <>
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th
                                  onClick={() => sorting6('user_business_unit_name')}
                                  className='  py-4'
                                >
                                  Business Unit{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting6('employee_id')} className='  py-4'>
                                  Consultant ID{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting7('display_name', 'full_name')}
                                  className='  py-4'
                                >
                                  Consultant Name{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting6('placement_code')} className='  py-4'>
                                  Placement Code{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_name')} className='  py-4'>
                                  Project Name{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('is_placement_project')}
                                  className='  py-4'
                                >
                                  Project Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('project_status_name')}
                                  className='  py-4'
                                >
                                  Project Status{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('start_date')} className='  py-4'>
                                  {' '}
                                  Start Date
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting12('project_status_name', 'cancel_date', 'end_date')
                                  }
                                  className='  py-4'
                                >
                                  {' '}
                                  End Date
                                  <span>
                                    {order12 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('employee_type')} className='  py-4'>
                                  Placement Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('month_name')} className='  py-4'>
                                  {' '}
                                  Month{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ProjectReportData?.length > 0 ? (
                                <>
                                  {ProjectReportData?.map((each: any, index: any) => (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 '
                                      key={index}
                                    >
                                      <td className=''>
                                        {each.user_business_unit_name
                                          ? each.user_business_unit_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.employee_id ? each.employee_id : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.display_name
                                          ? each.display_name
                                          : each.full_name
                                          ? each.full_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.placement_code ? each.placement_code : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.project_name ? each.project_name : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {/* {each.is_placement_project == false ? 'Bench' : 'Placement'} */}
                                        {each.is_placement_project
                                          ? each.is_placement_project == 1
                                            ? 'Placement'
                                            : each.is_placement_project == 2
                                            ? 'Bench'
                                            : each.is_placement_project == 3
                                            ? 'Internal'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.project_status_name ? each.project_status_name : '-'}
                                      </td>
                                      <td className='text-nowrap'>
                                        {each.start_date
                                          ? moment(each.start_date).format('DD-MMM-YYYY')
                                          : '-'}
                                      </td>
                                      <td className='text-nowrap'>
                                        {each.project_status_name
                                          ? each.project_status_name?.toLowerCase() == 'cancelled'
                                            ? each.cancel_date
                                              ? moment(each.cancel_date).format('DD-MMM-YYYY')
                                              : '-'
                                            : each.project_status_name?.toLowerCase() == 'completed'
                                            ? each.end_date
                                              ? moment(each.end_date).format('DD-MMM-YYYY')
                                              : '-'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.employee_type ? each.employee_type : '-'}
                                      </td>
                                      <td className=''>
                                        {each.month_name
                                          ? moment(each.month_name, 'MMMM').format('MMM')
                                          : '-'}{' '}
                                        {moment(test, 'ddd MMM DD YYYY').format('YYYY')}
                                      </td>
                                      <td>
                                        <div className='d-flex nowrap'>
                                          <Tooltip text={'Click here for missed entries'}>
                                            <a
                                              onClick={() => handleView(each)}
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                              <i className='fa fa-eye' aria-hidden='true'></i>
                                            </a>
                                          </Tooltip>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </>
                        </table>
                        {(ProjectReportData?.length == null || ProjectReportData?.length == 0) && (
                          <div className='text-center  fw-bold text-gray-800'>
                            No Records found{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <UsersListPagination />
              </KTCardBody>
              <div className='d-flex flex-stack py-2'>
                {/* {ProjectReportData?.length > 0 ? ( */}
                <div>
                  <Container className='p-0'>
                    <Row className='align-items-center'>
                      <Col xs='auto' className='pe-0'>
                        {' '}
                        <label className='form-label fw-semibold'>No. of projects</label>
                      </Col>

                      <Col>
                        <select
                          name='perPage'
                          className='form-select'
                          onChange={onChange}
                          value={perPage}
                        >
                          <option value='10'>10</option>
                          {/* <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option> */}
                          {/* <option value={ProjectReportCountData}>All</option> */}
                          <option value=''>All</option>
                        </select>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* ) : null} */}

                <div className='pr-150'>
                  {/* {ProjectReportData?.length > 0 ? ( */}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    pageCount={pages}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-right'}
                    activeClassName={'active'}
                    breakLabel={'...'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                  />
                  {/* ) : null} */}
                </div>
              </div>
              {MonthWiseViewPopOpen && (
                <OrgNonComplianceReportsPopup
                  reportData={MonthwiseProjectReportData}
                  handleClose={handleClosePopup}
                />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
