import React, {useState} from 'react'
import {default as Apiservices} from '../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'universal-cookie'

export default function SkillsRequiredPopup(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const cookies = new Cookies()

  const getEmployeeInfo = async () => {
    setShowBackDrop(true)
    const orgId = localStorage.getItem('logged_in_id')
    const response: any = await Apiservices.getEmployeeData(orgId)
    if (response && response?.userRequestDto && response?.userRequestDto?.skill) {
      localStorage.setItem('skillsrequired', response?.userRequestDto?.skill)
      return response?.userRequestDto?.skill
    }
  }

  const navigateProfile = async (blob: any) => {
    cookies.set('profileView', 'yes', {path: '/', domain: '.msrcosmos.com'})
    if (cookies.get('profileView')) {
      let resp = await getEmployeeInfo()
      if (resp) {
        props?.closeSKillPopup()
      } else {
        setTimeout(function () {
          window.open(blob, '_blank')
        }, 1000)
      }
    }
  }

  function removeAll() {
    let lRemove: any = [
      'token',
      'user_email',
      'org_list',
      'role_Name',
      'org_prefix',
      'org_id',
      'org_name',
      'tool_name',
      'ecdb_user_id',
      'userid',
      'Authorization',
      'NetSuiteToken',
      'enable_netsuite',
      'subsidary_name',
      'logged_in_id',
      'Announcement',
      'refresh_Token',
      'enable_quickbooks',
      'org_icon',
      'time_sheet_admin_screen_user_id',
      'MobileForceUpdateAccessbyEmail',
      'allowed_business_unit_id',
      'bu_list',
      'enable_unbilled',
      'invoice_access',
    ]
    for (let each of lRemove) {
      localStorage.removeItem(each)
    }
  }

  return (
    <>
      <Modal show={show} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Body>
          <p className='text-center text-gray-800 fs-6 text-transform-unset'>
            Your profile is incomplete. Please take a moment to complete your profile by clicking
            OK.
          </p>
          <div className='row justify-content-center gap-2 py-2'>
            <div className='col-auto'>
              {/* <button className='btn btn-primary' onClick={() => navigateProfile()}>
                Ok
              </button> */}
              {window.location.hostname === 'timesheets.msrcosmos.com' ? (
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    navigateProfile('https://launchpad.msrcosmos.com/#/home/dashboard')
                  }
                >
                  Ok
                </button>
              ) : (
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    navigateProfile('https://launchpad-uat.msrcosmos.com/#/home/dashboard')
                  }
                >
                  Ok
                </button>
              )}
            </div>
            <div className='col-auto'>
              {/* <button className='btn btn-danger' onClick={props.cancel}>
                Cancel
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}
